import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "../redux/modules/Main/action";
import { RootState } from "../redux";
import useLinkInfo from "../data/linkInfo";

export const useToken = (noTransaksi?: string) => {
  const dispatch = useDispatch();
  const token = useSelector((r: RootState) => r.main.token);
  const idTransaksi = useSelector((r: RootState) => r.main.idTransaksi);

  const { data, loading: linkDataLoading } = useLinkInfo(noTransaksi || idTransaksi, {
    showLinkExpired: false,
  });

  useEffect(() => {
    if (!linkDataLoading && data && data.token) {
      dispatch(setToken(data.token));
    }
  }, [data, linkDataLoading]);

  return token;
};

export default useToken;
