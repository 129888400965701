import { PURGE } from "redux-persist";

import {
  shippingState,
  shippingActionTypes,
  SET_OPSI_PENGAMBILAN,
  SET_PICK_UP_BY_COURIER,
  SET_ADDRESS_FOR_COURIER,
  ADD_CUSTOM_EXPEDITION,
} from "./types";

export default function ShippingReducer(
  state = initialState,
  action: shippingActionTypes,
): shippingState {
  switch (action.type) {
    case SET_OPSI_PENGAMBILAN:
      return { ...state, opsiPengambilan: action.payload.opsiPengambilan };

    case SET_PICK_UP_BY_COURIER:
      return {
        ...state,
        pickUpByCourier: action.payload.pickUpByCourier,
        addressForCourier: null,
      };

    case SET_ADDRESS_FOR_COURIER:
      return { ...state, addressForCourier: action.payload.addressForCourier };

    case ADD_CUSTOM_EXPEDITION:
      return {
        ...state,
        customExpeditions: [...state.customExpeditions, { nama: action.payload.expedition }],
      };

    case PURGE:
      return initialState;

    default:
      return state;
  }
}

export const initialState: shippingState = {
  opsiPengambilan: "dikirim",
  pickUpByCourier: false,
  addressForCourier: null,
  customExpeditions: [],
};
