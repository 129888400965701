import { options } from "preact";

// Fix Preact rendering when Google Translate breaks the DOM
const FONT_AS_TEXT = {
  localName: {
    value: null,
  },
  nodeType: {
    value: 3,
  },
  data: {
    get() {
      return this._data;
    },
    set(v) {
      v += "";
      if (v === this._data) return;
      const t = document.createTextNode((this._data = v));
      this.parentNode.replaceChild(t, this);
      this.__v.__e = t;
    },
  },
};
function check(child, ref) {
  if (ref && ref.nodeType === 3 && child) {
    let intercept = child.localName === "msreadoutspan";
    if (!intercept && child.localName === "font") {
      for (let i in child) {
        if (i.startsWith("_gt_")) {
          intercept = true;
          break;
        }
      }
    }
    if (intercept) {
      ref._font = child;
      child._data = ref.data;
      Object.defineProperties(child, FONT_AS_TEXT);
    }
  }
}
const ib = Element.prototype.insertBefore;
Element.prototype.insertBefore = function (child, ref) {
  check(child, ref);
  return ib.apply(this, arguments);
};
const rc = Element.prototype.replaceChild;
Element.prototype.replaceChild = function (child, ref) {
  check(child, ref);
  return rc.apply(this, arguments);
};
const oldDiffedHook = options.diffed;
options.diffed = (vnode) => {
  if (vnode.type === null && vnode.__e) {
    const font = vnode.__e._font;
    if (font) {
      vnode.__e = font;
      font.__v = vnode;
      font.data = vnode.props;
    }
  }
  if (oldDiffedHook) {
    oldDiffedHook(vnode);
  }
};
