import {
  konfirmasiActionTypes,
  SET_KONFIRMASINOORDER,
  SET_KONFIRMASI,
  konfirmasiState,
  SET_KONFIRMASIREKENING,
  SET_KONFIRMASIATASNAMA,
  SET_KONFIRMASIRINCIAN,
} from "./types";

export default function KonfirmasiReducer(
  state = initialState,
  action: konfirmasiActionTypes,
): konfirmasiState {
  switch (action.type) {
    case SET_KONFIRMASINOORDER:
      return {
        ...state,
        konfirmasinoorderitems: action.payload.konfirmasinoorderitems,
      };

    case SET_KONFIRMASI:
      return {
        ...state,
        konfirmasiItems: action.payload.konfirmasiItems,
      };

    case SET_KONFIRMASIREKENING:
      return {
        ...state,
        konfirmasirekeningitems: action.payload.konfirmasirekeningitems,
      };

    case SET_KONFIRMASIATASNAMA:
      return {
        ...state,
        konfirmasiatasnamaitems: action.payload.konfirmasiatasnamaitems,
      };

    case SET_KONFIRMASIRINCIAN:
      return {
        ...state,
        KonfirmasiRincianItems: action.payload.konfirmasirincianitems,
      };

    default:
      return state;
  }
}

export const initialState: konfirmasiState = {
  konfirmasinoorderitems: [],
  konfirmasiatasnamaitems: [],
  konfirmasiItems: [],
  konfirmasirekeningitems: [],
  KonfirmasiRincianItems: [],
};
