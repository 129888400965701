import {
  statusorderState,
  statusorderActionTypes,
  SET_STATUSORDER,
  SET_STATUSORDERPAYMENT,
  SET_STATUSORDERBOX,
  SET_STATUSORDERSENT,
  SET_STATUSORDERDETAILROLLAN,
  SET_STATUSORDERDETAILKGAN,
  SET_STATUSORDERDETAILKATALOG,
  ADD_STATUSORDER_ITEM,
} from "./types";

export default function CartReducer(
  state = initialState,
  action: statusorderActionTypes,
): statusorderState {
  switch (action.type) {
    case SET_STATUSORDER:
      return {
        ...state,
        statusorderItems: action.payload.statusorderItems,
      };
    case SET_STATUSORDERPAYMENT:
      return {
        ...state,
        statusorderpaymentItems: action.payload.statusorderpaymentItems,
      };
    case SET_STATUSORDERBOX:
      return {
        ...state,
        statusorderboxItems: action.payload.statusorderboxItems,
      };
    case SET_STATUSORDERSENT:
      return {
        ...state,
        statusordersentItems: action.payload.statusordersentItems,
      };
    case SET_STATUSORDERDETAILROLLAN:
      return {
        ...state,
        statusorderdetailrollanItems: action.payload.statusorderdetailrollanItems,
      };
    case SET_STATUSORDERDETAILKGAN:
      return {
        ...state,
        statusorderdetailkganItems: action.payload.statusorderdetailkganItems,
      };
    case SET_STATUSORDERDETAILKATALOG:
      return {
        ...state,
        statusorderdetailkatalogItems: action.payload.statusorderdetailkatalogItems,
      };

    case ADD_STATUSORDER_ITEM:
      return {
        ...state,
        statusorderItems: [...state.statusorderItems, action.payload.statusorderItem],
      };

    default:
      return state;
  }
}

export const initialState: statusorderState = {
  statusorderItems: [],
  statusorderpaymentItems: [],
  statusorderboxItems: [],
  statusordersentItems: [],
  statusorderdetailrollanItems: [],
  statusorderdetailkganItems: [],
  statusorderdetailkatalogItems: [],
};
