import { combineReducers } from "redux";
import { createMigrate, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import main from "./Main/reducer";
import order from "./Order/reducer";
import payment from "./Payment/reducer";
import shipping from "./Shipping/reducer";
import tour from "./TourGuide/reducer";
import Konfirmasi from "./Konfirmasi/reducer";
import KritikSaran from "./KritikSaran/reducer";
import StatusOrder from "./StatusOrder/reducer";

const configPersistMain: PersistConfig<ReturnType<typeof main>> = {
  key: "main",
  storage,
  whitelist: ["selectedCabang", "selectedAddress", "noTransaksi"],
  version: 7,
  migrate: createMigrate(
    {
      6: (state) => {
        if (!state) return;
        return { ...state, selectedAddress: {} };
      },
      7: (state) => {
        if (!state) return;
        return { ...state, idTransaksi: undefined };
      },
    },
    { debug: false },
  ),
};

const rootReducer = combineReducers({
  main: persistReducer(configPersistMain, main),
  order,
  payment,
  shipping,
  tour,
  Konfirmasi,
  KritikSaran,
  StatusOrder,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
