import { useEffect } from "react";
import TagManager from "react-gtm-module";

import { GTM_ID } from "../strings";

export const useGTM = (delay = 3000) => {
  useEffect(() => {
    setTimeout(() => {
      TagManager.initialize({ gtmId: GTM_ID });
    }, delay);
  }, []);
};

export default useGTM;
