import { useEffect } from "react";
import { hotjar } from "react-hotjar";

import { HOTJAR_ID } from "../strings";

export const useHotjar = (delay = 3000) => {
  useEffect(() => {
    setTimeout(() => {
      hotjar.initialize(HOTJAR_ID, 6);
    }, delay);
  }, []);
};

export default useHotjar;
