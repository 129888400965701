import axios from "axios";
import axiosRetry from "axios-retry";
import useToken from "./useToken";
import { store } from "../redux";

export const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
axios.interceptors.response.use(async (response) => {
  if (response.data.status === 403) {
    const state = store.getState();
    const path = window.location.pathname;
    let url = path.split("/");
    url[1] = "A";
    state.main.inProtectedRoute && window.open(url.join("/"), "_self");
  }

  return response;
});

axiosRetry(axios, {
  retries: 8,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.code === "ECONNABORTED";
  },
});

export const useAxiosInterceptors = () => {
  const token = useToken();

  if (token) {
    axios.interceptors.request.use(async (config) => {
      if (!config.headers.authorization) {
        config.headers.authorization = token;
      }
      return config;
    });
  }
};

export default useAxiosInterceptors;
