import { CreateSlice } from "./useStore";
import { Address, OpsiPengambilan, OPSI_DIKIRIM } from "../types";

export type ShippingSlice = {
  selectedAddress: Address | null;
  selectedEkspedisi: string;
  opsiPengambilan: OpsiPengambilan;
  pickUpByCourier: boolean;
  addressForCourier: Address | null;
  customExpeditions: { nama: string }[];
  selectAddress: (address: Address | null) => any;
  selectEkspedisi: (ekspedisi: string) => any;
  setOpsiPengambilan: (opsiPengambilan: OpsiPengambilan) => any;
  setPickUpByCourier: (pickUpByCourier: boolean) => any;
  setAddressForCourier: (addressForCourier: Address | null) => any;
  addCustomExpeditions: (expedition: string) => any;
};

export const initialState = {
  selectedAddress: null,
  selectedEkspedisi: "",
  opsiPengambilan: OPSI_DIKIRIM as OpsiPengambilan,
  pickUpByCourier: false,
  addressForCourier: null,
  customExpeditions: [],
};

const createShippingSlice: CreateSlice<ShippingSlice> = (set, get) => ({
  ...initialState,
  selectAddress: (selectedAddress) => set({ selectedAddress }),
  selectEkspedisi: (selectedEkspedisi) => set({ selectedEkspedisi }),
  setOpsiPengambilan: (opsiPengambilan) => set({ opsiPengambilan }),
  setPickUpByCourier: (pickUpByCourier) => set({ pickUpByCourier, addressForCourier: null }),
  setAddressForCourier: (addressForCourier) => set({ addressForCourier }),
  addCustomExpeditions: (newExpedition) =>
    set({
      customExpeditions: [...get().customExpeditions, { nama: newExpedition }],
    }),
});

export default createShippingSlice;
