import {
  tourGuideState,
  tourGuideActionTypes,
  SET_TOUR_GUIDE_SHOW,
  SET_CURRENT_TOUR,
  SET_CURRENT_STEP,
} from "./type";

export default function tourGuideReducer(
  state = initialState,
  action: tourGuideActionTypes,
): tourGuideState {
  switch (action.type) {
    case SET_TOUR_GUIDE_SHOW:
      return {
        ...state,
        isShow: action.payload.isShow,
      };

    case SET_CURRENT_TOUR:
      return {
        ...state,
        currentTour: action.payload.currentTour,
      };

    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep,
      };

    default:
      return state;
  }
}

export const initialState: tourGuideState = {
  isShow: false,
  currentTour: 0,
  currentStep: -1,
};
