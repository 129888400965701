import {
  tourGuideActionTypes,
  SET_TOUR_GUIDE_SHOW,
  SET_CURRENT_TOUR,
  SET_CURRENT_STEP,
} from "./type";

export const setTourGuideShow = (isShow: boolean): tourGuideActionTypes => ({
  type: SET_TOUR_GUIDE_SHOW,
  payload: { isShow },
});

export const setCurrentTour = (currentTour: number): tourGuideActionTypes => ({
  type: SET_CURRENT_TOUR,
  payload: { currentTour },
});

export const setCurrentStep = (currentStep: number): tourGuideActionTypes => ({
  type: SET_CURRENT_STEP,
  payload: { currentStep },
});
