import { minifyScript } from "@/helpers/minifyScript";
import { ANYTRACK_ID } from "@/strings";

export default function AnyTrackPixel() {
  return (
    <script
      key={`AnyTrackPixel-${ANYTRACK_ID}`}
      id={`AnyTrackPixel-${ANYTRACK_ID}`}
      dangerouslySetInnerHTML={{
        __html: minifyScript(`
          !function(e,t,n,s,a){
            (a=t.createElement(n)).async=!0;
            a.src="https://assets.anytrack.io/${ANYTRACK_ID}.js";
            (t=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,t);
            e[s]=e[s]||function(){
                (e[s].q=e[s].q||[]).push(arguments)
            }
        }(window,document,"script","AnyTrack");
    `),
      }}
    />
  );
}
