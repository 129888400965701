import useSWR from "swr";
import axios from "axios";
import { HOST } from "../strings";

const fetcher = async (url: string) => (await axios.get(url)).data;

export const useSelectedCabang = (notransaksi: string) => {
  const { data, mutate, error } = useSWR(
    notransaksi ? `${HOST}/getcabangtransaksi/${notransaksi}` : null,
    fetcher,
  );

  const loading = !data && !error;

  const payload = data?.values;

  return {
    loading,
    error,
    data: payload,
    mutate,
  };
};

export default useSelectedCabang;
