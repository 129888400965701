import { GA_ID } from "@/strings";
import { minifyScript } from "@/helpers/minifyScript";

export default function TheGA() {
  if (!GA_ID) return null;
  return (
    <>
      <script
        key={`GA-Script-${GA_ID}`}
        id={`GA-Script-${GA_ID}`}
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
      />
      <script
        key={`GA-Init-${GA_ID}`}
        id={`GA-Init-${GA_ID}`}
        dangerouslySetInnerHTML={{
          __html: minifyScript(`
            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag(){window.dataLayer.push(arguments)};
            window.gtag('js', new Date());
            window.gtag('config', '${GA_ID}');
          `),
        }}
      />
    </>
  );
}
