import { CreateSlice } from "./useStore";
import { Cabang, WarnaKain } from "../types";

export type MainSlice = {
  selectedCabang: Cabang;
  selectedGroupKainId: number;
  selectedJenisKainName: string;
  selectedWarnaKain: WarnaKain | null;
  selectCabang: (cabang: Cabang) => any;
  selectGroupKain: (groupKainId: number) => any;
  selectJenisKain: (jenisKainName: string) => any;
  selectWarnaKain: (warnaKain: WarnaKain | null) => any;
};

export const initialState = {
  selectedCabang: {
    id: -1,
    alias: "",
    kota: "",
    nama_cabang: "",
    alamat: "",
  },
  selectedGroupKainId: -1,
  selectedJenisKainName: "",
  selectedWarnaKain: null,
};

const createMainSlice: CreateSlice<MainSlice> = (set) => ({
  ...initialState,
  selectCabang: (selectedCabang) => set({ selectedCabang }),
  selectGroupKain: (selectedGroupKainId) => set({ selectedGroupKainId }),
  selectJenisKain: (selectedJenisKainName) => set({ selectedJenisKainName }),
  selectWarnaKain: (selectedWarnaKain) => set({ selectedWarnaKain }),
});

export default createMainSlice;
