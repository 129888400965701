import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { isIOS } from "@/helpers/isIOS";
import { Page } from "@/components/Layout";
import useMedia from "use-media";
import styles from "../ExpiredOrder/ExpiredOrder.module.scss";
import ExpiredOrderM from "@/assets/SVGs/ic-order-expired-m.svg";
import IcExpiredOrder from "@/assets/SVGs/ic-order-expired-d.svg";
import { ReactComponent as BackgroundDesktop } from "@/assets/SVGs/orderPlacedBackgroundDesktop.svg";
import WhatsappSvg from "@/assets/SVGs/whatsapp-white.svg";
import { StringParam, useQueryParam } from "use-query-params";
import { KNITTO_WHATSAPP_NUMBER } from "@/strings";
import classNames from "classnames";

const ExpiredOrder: React.FC = () => {
  const history = useHistory();
  const isMobile = useMedia({ maxWidth: "991.98px" });
  const [page] = useQueryParam("p", StringParam);
  const [noOrderQueryParam] = useQueryParam("no_order", StringParam);

  const message = useMemo(() => {
    switch (page) {
      default:
        return "Saya ingin mendapatkan link baru ke website Portal";
    }
  }, [page]);

  const waButtonClickHandler = useCallback(() => {
    window.open(`https://wa.me/${KNITTO_WHATSAPP_NUMBER}?text=${message}`, "_self");
  }, [message]);

  useEffect(() => {
    if (noOrderQueryParam === undefined) {
      history.push("/");
    }
  }, [noOrderQueryParam]);

  return (
    <Page iOS={isIOS()} className={styles.page}>
      {isMobile ? null : (
        <div className={styles.backgroundDesktop}>
          <BackgroundDesktop />
        </div>
      )}
      <div className={styles.pageContainer}>
        <div className={styles.image}>
          <img src={isMobile ? ExpiredOrderM : IcExpiredOrder} />
        </div>
        <span className={styles.dividerText}>...</span>
        <h6 className={styles.headingText}>
          Order dengan nomor order {noOrderQueryParam} sudah dilakukan konfirmasi bayar. Terimakasih
          telah berbelanja kebutuhan kain di Knitto Textiles
        </h6>
      </div>
      <p
        className={classNames(`button kp-0 km-0 kmb-3-2 ktext-center kmx-1`, isMobile && "kmt-6-9")}
      >
        Jika kamu membutuhkan bantuan, silahkan
        {!isMobile && <br />} hubungi admin kami melalui Whatsapp
      </p>
      <button className={classNames(styles.whastAppBtn, "kmb-3-2")} onClick={waButtonClickHandler}>
        <div className={styles.whatsAppIcon}>
          <img src={WhatsappSvg} alt="wa-circle" />
        </div>
        <p className="button white-100">Hubungi Admin Via Whatsapp</p>
      </button>
    </Page>
  );
};

export default ExpiredOrder;
