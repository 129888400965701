import React from "react";
import styles from "./NotifSlowConnection.module.scss";
import { ReactComponent as IcNoSignal } from "../../assets/SVGs/ic-no-signal.svg";
import { ReactComponent as IcClose } from "../../assets/SVGs/ic-close.svg";

type Props = {
  onCLose: () => void;
};
const NotifSlowConnection: React.FC<Props> = ({ onCLose }) => (
  <div className={styles.container}>
    <div className={styles.notif}>
      <div>
        <IcNoSignal />
      </div>
      <p className="hint white-100">
        Ada kendala jaringan internet. Mohon cek ulang koneksi internet kamu atau order melalui
        WhatsApp
      </p>
      <div style={{ cursor: "pointer", height: "24px" }} onClick={onCLose}>
        <IcClose />
      </div>
    </div>
  </div>
);

export default NotifSlowConnection;
