import { CreateSlice } from "./useStore";

export type DokumentasiUISlice = {
  showDokumentasi: boolean;
  setShowDokumentasi: (showDokumentasi: boolean) => any;
  toggleShowDokumentasi: () => any;
};

const createDokumentasiUISlice: CreateSlice<DokumentasiUISlice> = (set) => {
  const initialState = sessionStorage.getItem("showDokumentasi");
  const parsedInitialState = initialState ? JSON.parse(initialState) : false;

  const setStateAndSaveToSessionStorage = (showDokumentasi: boolean) => {
    set({ showDokumentasi });

    sessionStorage.setItem("showDokumentasi", JSON.stringify(showDokumentasi));
  };

  return {
    showDokumentasi: parsedInitialState,
    setShowDokumentasi: setStateAndSaveToSessionStorage,
    toggleShowDokumentasi: () => {
      set((state) => {
        const updatedState = !state.showDokumentasi;

        sessionStorage.setItem("showDokumentasi", JSON.stringify(updatedState));

        return { showDokumentasi: updatedState };
      });
    },
  };
};

export default createDokumentasiUISlice;
