import { paymentState, SET_REKENING, paymentActionTypes } from "./types";

export default function tourGuideReducer(
  state = initialState,
  action: paymentActionTypes,
): paymentState {
  switch (action.type) {
    case SET_REKENING:
      return {
        ...state,
        rekening: action.payload.rekening,
      };

    default:
      return state;
  }
}

export const initialState: paymentState = {};
