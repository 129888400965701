import { CreateSlice } from "./useStore";
import { FabricVariant, FabricColour, Clothes, OpsiSwitcher, OPSI_CLOTHES } from "../types";

export type CalculatorSlice = {
  selectedJenisPakaian: Clothes | null;
  selectedFabric: FabricVariant | null;
  selectedFabricColour: FabricColour | null;
  opsiSwitcher: OpsiSwitcher;
  totalKG: number;
  opsiSize: string;
  accChecked: boolean;
  selectJenisPakaian: (jenisPakaian: Clothes | null) => any;
  selectFabric: (fabric: FabricVariant | null) => any;
  selectFabricColour: (color: FabricColour | null) => any;
  setOpsiSwitcher: (opsi: OpsiSwitcher) => any;
  setTotalKG: (totalKG: number) => any;
  setOpsiSize: (opsiSize: string) => any;
  setAccChecked: (accChecked: boolean) => any;
};

export const initialState = {
  selectedJenisPakaian: null,
  selectedFabric: null,
  selectedFabricColour: null,
  opsiSwitcher: OPSI_CLOTHES as OpsiSwitcher,
  totalKG: 0,
  opsiSize: "S",
  accChecked: false,
};

const createMainSlice: CreateSlice<CalculatorSlice> = (set) => ({
  ...initialState,
  selectJenisPakaian: (selectedJenisPakaian) => set({ selectedJenisPakaian }),
  selectFabric: (selectedFabric) => set({ selectedFabric }),
  selectFabricColour: (selectedFabricColour) => set({ selectedFabricColour }),
  setOpsiSwitcher: (opsiSwitcher) => set({ opsiSwitcher }),
  setTotalKG: (totalKG) => set({ totalKG }),
  setOpsiSize: (opsiSize) => set({ opsiSize }),
  setAccChecked: (accChecked) => set({ accChecked }),
});

export default createMainSlice;
