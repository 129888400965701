import { createStore, compose } from "redux";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./modules";
import { RootState } from "./modules";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  (process.env.NODE_ENV === "development" && composeWithDevTools()) || compose();

const exported = (() => {
  let store = createStore(rootReducer, composeEnhancers);
  let persistor = persistStore(store);
  return { store, persistor };
})();

export default exported;
export const { persistor, store } = exported;
export type { RootState };
