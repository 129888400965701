import { kritiksaranState, kritiksaranActionTypes, SET_KRITIKSARANORDER } from "./types";

export default function KritikSaranReducer(
  state = initialState,
  action: kritiksaranActionTypes,
): kritiksaranState {
  switch (action.type) {
    case SET_KRITIKSARANORDER:
      return {
        ...state,
        kritiksaranorderitems: action.payload.kritiksaranorderitems,
      };

    default:
      return state;
  }
}

export const initialState: kritiksaranState = {
  kritiksaranorderitems: [],
};
