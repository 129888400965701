import { RefObject } from "react";

export type RefsObject = RefObject<HTMLTableRowElement>[];

export interface KritikSaranOrderItem {
  noOrder: string;
}

export interface kritiksaranState {
  kritiksaranorderitems: KritikSaranOrderItem[];
}

export const SET_KRITIKSARANORDER = "@konfirmasi/SET_KONFIRMASINOORDER";

interface setKritikSaranOrderItems {
  type: typeof SET_KRITIKSARANORDER;
  payload: { kritiksaranorderitems: KritikSaranOrderItem[] };
}

export type kritiksaranActionTypes = setKritikSaranOrderItems;
