import { Page } from "@/components/Layout";
import isIOS from "@/helpers/isIOS";
import React, { useCallback, useMemo } from "react";
import useMedia from "use-media";
import { ReactComponent as BackgroundDesktop } from "@/assets/SVGs/orderPlacedBackgroundDesktop.svg";
import cn from "classnames";
import styles from "../ExpiredOrder/ExpiredOrder.module.scss";
import IcExpiredOrderM from "@/assets/SVGs/ic-expired-cancel-m.svg";
import IcExpiredOrderD from "@/assets/SVGs/ic-expired-cancel-d.svg";
import WhatsappSvg from "@/assets/SVGs/whatsapp-white.svg";
import { KNITTO_WHATSAPP_NUMBER } from "@/strings";
import { useQueryParam, StringParam } from "use-query-params";

const ExpiredOrderCancel: React.FC = () => {
  const isMobile = useMedia({ maxWidth: "991.98px" });
  const [noOrderQueryParam] = useQueryParam("no_order", StringParam);

  const message = useMemo(() => {
    return "Saya ingin mendapatkan link baru ke website Portal";
  }, []);

  const waButtonClickHandler = useCallback(() => {
    window.open(`https://wa.me/${KNITTO_WHATSAPP_NUMBER}?text=${message}`, "_self");
  }, [message]);

  return (
    <Page iOS={isIOS()} className={styles.page}>
      {isMobile ? null : (
        <div className={styles.backgroundDesktop}>
          <BackgroundDesktop />
        </div>
      )}
      <div className={styles.pageContainer}>
        <div className={styles.image}>
          {isMobile ? (
            <img src={IcExpiredOrderM} style={{ maxWidth: "190px", width: "120px" }}></img>
          ) : (
            <img src={IcExpiredOrderD}></img>
          )}
        </div>
        <span className={styles.dividerText} style={{ marginTop: "0.5rem" }}>
          ...
        </span>
        <h6 className={styles.headingText}>
          Maaf, Order {noOrderQueryParam ? `dengan nomor ${noOrderQueryParam} ` : " "}telah
          dibatalkan
        </h6>
      </div>
      <p className={cn(`button kp-0 km-0 kmb-3-2 ktext-center kmx-1`, isMobile && "kmt-6-9")}>
        Jika kamu membutuhkan bantuan, silahkan
        {!isMobile && <br />} hubungi admin kami melalui Whatsapp
      </p>
      <button className={cn(styles.whastAppBtn, "kmb-3-2")} onClick={waButtonClickHandler}>
        <div className={styles.whatsAppIcon}>
          <img src={WhatsappSvg} alt="wa-circle" />
        </div>
        <p className="button white-100">Hubungi Admin Via Whatsapp</p>
      </button>
    </Page>
  );
};

export default ExpiredOrderCancel;
