import { RefObject } from "react";

export type RefsObject = RefObject<HTMLTableRowElement>[];

export interface StatusOrderItem {
  noOrder: string;
  tanggalOrder: string;
  statusOrder: string;
  noResi: string;
  cabang: string;
  fhoto: string | null;
}
export interface StatusOrderPaymentItem {
  noOrder: string;
  tanggalOrder: string;
  statusOrder: string;
  noResi: string;
  cabang: string;
  fhoto: string | null;
}
export interface StatusOrderBoxItem {
  noOrder: string;
  tanggalOrder: string;
  statusOrder: string;
  noResi: string;
  cabang: string;
  fhoto: string | null;
}
export interface StatusOrderSentItem {
  noOrder: string;
  tanggalOrder: string;
  statusOrder: string;
  noResi: string;
  cabang: string;
  fhoto: string | null;
}

export interface AksesorisType {
  tipeKain: string;
  quantity: number;
}
export interface StatusOrderDetailRollanItem {
  jenisKain: string;
  warna: string;
  tipeKain: string;
  quantity: number;
  hex8: string;
}
export interface StatusOrderDetailKganItem {
  jenisKain: string;
  warna: string;
  quantity: number;
  hex8: string;
  dataAksesoris: AksesorisType[];
}
export interface StatusOrderDetailKatalogItem {
  namaKatalog: string;
  quantity: number;
  imageUrl: string;
}

export interface statusorderState {
  statusorderItems: StatusOrderItem[];
  statusorderpaymentItems: StatusOrderPaymentItem[];
  statusorderboxItems: StatusOrderBoxItem[];
  statusordersentItems: StatusOrderSentItem[];
  statusorderdetailrollanItems: StatusOrderDetailRollanItem[];
  statusorderdetailkganItems: StatusOrderDetailKganItem[];
  statusorderdetailkatalogItems: StatusOrderDetailKatalogItem[];
}

export const SET_STATUSORDER = "@statusorder/SET_STATUSORDER";
export const SET_STATUSORDERPAYMENT = "@statusorder/SET_STATUSORDERPAYMENT";
export const SET_STATUSORDERBOX = "@statusorder/SET_STATUSORDERBOX";
export const SET_STATUSORDERSENT = "@statusorder/SET_STATUSORDERSENT";
export const SET_STATUSORDERDETAILROLLAN = "@statusorder/SET_STATUSORDERDETAILROLLAN";
export const SET_STATUSORDERDETAILKGAN = "@statusorder/SET_STATUSORDERDETAILKGAN";
export const SET_STATUSORDERDETAILKATALOG = "@statusorder/SET_STATUSORDERDETAILKATALOG";
export const ADD_STATUSORDER_ITEM = "@statusorder/ADD_STATUSORDER_ITEM";

interface setStatusOrderItems {
  type: typeof SET_STATUSORDER;
  payload: { statusorderItems: StatusOrderItem[] };
}
interface setStatusOrderPaymentItems {
  type: typeof SET_STATUSORDERPAYMENT;
  payload: { statusorderpaymentItems: StatusOrderPaymentItem[] };
}
interface setStatusOrderBoxItems {
  type: typeof SET_STATUSORDERBOX;
  payload: { statusorderboxItems: StatusOrderBoxItem[] };
}
interface setStatusOrderSentItems {
  type: typeof SET_STATUSORDERSENT;
  payload: { statusordersentItems: StatusOrderSentItem[] };
}
interface setStatusOrderDetailRollanItems {
  type: typeof SET_STATUSORDERDETAILROLLAN;
  payload: { statusorderdetailrollanItems: StatusOrderDetailRollanItem[] };
}
interface setStatusOrderDetailKganItems {
  type: typeof SET_STATUSORDERDETAILKGAN;
  payload: { statusorderdetailkganItems: StatusOrderDetailKganItem[] };
}
interface setStatusOrderDetailKatalogItems {
  type: typeof SET_STATUSORDERDETAILKATALOG;
  payload: { statusorderdetailkatalogItems: StatusOrderDetailKatalogItem[] };
}

interface addStatusOrderItems {
  type: typeof ADD_STATUSORDER_ITEM;
  payload: { statusorderItem: StatusOrderItem };
}

export type statusorderActionTypes =
  | setStatusOrderItems
  | setStatusOrderPaymentItems
  | setStatusOrderBoxItems
  | setStatusOrderSentItems
  | setStatusOrderDetailRollanItems
  | setStatusOrderDetailKganItems
  | setStatusOrderDetailKatalogItems
  | addStatusOrderItems;
