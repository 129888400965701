import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import isDevelopment from "./isDevel";
import Cookies from "js-cookie";
import { gtmPageView } from "@/libs/analytics/gtm";
import { useLocation } from "react-router-dom";
import useStore from "@/stores/useStore";
import useReqRefId from "@/api/reqRefId";

const usePixel = () => {
  const location = useLocation();
  const { refId } = useStore((state) => state.selectedOrder);
  const setRefId = useStore((state) => state.setRefId);
  const pixelOptions: ReactPixel.Options = {
    autoConfig: true,
    debug: isDevelopment,
  };

  const baseUrl = location.pathname.split("/").slice(0, -1).join("/");

  const getNewRefId = async () => {
    const newId = await useReqRefId();
    return setRefId(newId);
  };

  useEffect(() => {
    // ReactPixel.init("224435787990661", undefined, pixelOptions);
    // ReactPixel.pageView();

    // Do something when the route changes
    // fbPageView({ eventId });
    // ttPixelTrackPage({
    //   event_id: eventId,
    //   instance: baseUrl,
    //   track_event: "Pageview",
    // });

    if (refId === "") getNewRefId();

    // gtmPageView(baseUrl);
  }, [location]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("gclid");
    if (paramValue) Cookies.set("gclid", paramValue);
  }, []);
  return null;
};

export default usePixel;
