export interface tourGuideState {
  isShow: boolean;
  currentStep: number;
  currentTour: number;
}

export const SET_TOUR_GUIDE_SHOW = "@tour/SET_TOUR_GUIDE_SHOW";
export const SET_CURRENT_TOUR = "@tour/SET_CURRENT_TOUR";
export const SET_CURRENT_STEP = "@tour/SET_CURRENT_STEP";

interface setTourGuideShow {
  type: typeof SET_TOUR_GUIDE_SHOW;
  payload: { isShow: boolean };
}

interface setCurrentTour {
  type: typeof SET_CURRENT_TOUR;
  payload: { currentTour: number };
}

interface setCurrentStep {
  type: typeof SET_CURRENT_STEP;
  payload: { currentStep: number };
}

export type tourGuideActionTypes = setTourGuideShow | setCurrentTour | setCurrentStep;
