import { PURGE } from "redux-persist";

import {
  mainState,
  mainActionTypes,
  SELECT_ADDRESS,
  SELECT_EKSPEDISI,
  SELECT_CABANG,
  SELECT_GROUP_KAIN,
  SELECT_JENIS_KAIN,
  SELECT_WARNA_KAIN,
  SET_TOKEN,
  SET_ID_TRANSAKSI,
  SET_NO_TRANSAKSI,
  SET_IN_PROTECTED_ROUTE,
  SELECT_JENIS_PAKAIAN,
  SET_NO_TRANSAKSI_NO_HP,
} from "./types";

export default function MainReducer(state = initialState, action: mainActionTypes): mainState {
  switch (action.type) {
    case SELECT_ADDRESS:
      return {
        ...state,
        selectedAddress: {
          ...state.selectedAddress,
          [state.idTransaksi || ""]: action.payload.address,
        },
      };

    case SELECT_EKSPEDISI:
      return { ...state, selectedEkspedisi: action.payload.ekspedisi };

    case SELECT_CABANG:
      return { ...state, selectedCabang: action.payload.cabang };

    case SELECT_GROUP_KAIN:
      return { ...state, selectedGroupKainId: action.payload.groupKainId };

    case SELECT_JENIS_KAIN:
      return { ...state, selectedJenisKainName: action.payload.jenisKainName };

    case SELECT_WARNA_KAIN:
      return { ...state, selectedWarnaKain: action.payload.warnaKain };

    case SELECT_JENIS_PAKAIAN:
      return { ...state, selectJenisPakaian: action.payload.jenisPakaian };

    case SET_TOKEN:
      return { ...state, token: action.payload.token };

    case SET_ID_TRANSAKSI:
      return { ...state, idTransaksi: action.payload.idTransaksi };

    case SET_NO_TRANSAKSI:
      return { ...state, noTransaksi: action.payload.noTransaksi };

    case SET_NO_TRANSAKSI_NO_HP:
      return {
        ...state,
        noTransaksi: action.payload.noTransaksi,
        noHp: action.payload.noHp,
        nama: action.payload.nama,
      };

    case SET_IN_PROTECTED_ROUTE:
      return { ...state, inProtectedRoute: action.payload.inProtectedRoute };

    case PURGE:
      return initialState;

    default:
      return state;
  }
}

export const initialState: mainState = {
  selectedAddress: {},
  selectedEkspedisi: "",
  selectedCabang: { id: -1, alias: "", kota: "", nama_cabang: "", alamat: "" },
  selectedGroupKainId: -1,
  selectedJenisKainName: "",
  selectedWarnaKain: null,
  selectJenisPakaian: "O-Neck T-Shirt",
  noTransaksi: "",
  inProtectedRoute: false,
  noHp: "",
  nama: "",
};
