import { minifyScript } from "@/helpers/minifyScript";
import { GTM_ID } from "@/strings";
export default function TheGTM() {
  if (!GTM_ID) return null;
  return (
    <>
      <script
        key={`GTM-Script-${GTM_ID}`}
        id={`GTM-Script-${GTM_ID}`}
        // strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: minifyScript(`
            !function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            }(window,document,'script','dataLayer','${GTM_ID}');
          `),
        }}
      />

      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <iframe
              src='https://www.googletagmanager.com/ns.html?id=${GTM_ID}'
              height='0'
              width='0'
              style='display: none; visibility: hidden'
            ></iframe>
          `,
        }}
      />
    </>
  );
}
