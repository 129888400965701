import "./index.scss";
import React from "react";
import { h } from "preact";
import axios from "axios";
import { hydrate, render } from "react-dom";
import { SWRConfig } from "swr";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { setup } from "goober";
import { prefix } from "goober/prefixer";
import { persistor, store } from "./redux";
import App from "./App";
import Loading from "./pages/Loading";
import DokumentasiUIProvider from "./components/DokumentasiUI/Provider";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import TheGA from "./libs/analytics/TheGA";
import TheAnyTrackPixel from "./libs/analytics/TheAnyTrackPixel";
import TheGTM from "./libs/analytics/TheGTM";

if (process.env.REACT_APP_SENTRY_DSN)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 0.1,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["https://apiv2.tigapuluhlima.id/"],
      }),
      new CaptureConsole({
        levels: ["error"],
      }),
    ],
  });

const rootElement = document.getElementById("root");

setup(h, prefix);

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

const AppWrapper = (
  <React.StrictMode>
    <TheGA />
    <TheGTM />
    {/* <TheFBPixel />
    <TheTiktokPixel /> */}
    <TheAnyTrackPixel />
    <SWRConfig
      value={{
        fetcher,
        onError: (err) => {
          console.error(err);
        },
      }}
    >
      <Provider store={store}>
        <DokumentasiUIProvider>
          <PersistGate persistor={persistor} loading={<Loading />}>
            <App />
          </PersistGate>
        </DokumentasiUIProvider>
      </Provider>
    </SWRConfig>
  </React.StrictMode>
);

if (rootElement?.hasChildNodes()) {
  hydrate(AppWrapper, rootElement);
} else {
  render(AppWrapper, rootElement);
}
