import create, { SetState, GetState } from "zustand";
import { persist } from "zustand/middleware";

import createMainSlice, { MainSlice } from "./createMainSlice";
import createSessionSlice, { SessionSlice } from "./createSessionSlice";
import createOrderSlice, { OrderSlice } from "./createOrderSlice";
import createShippingSlice, { ShippingSlice } from "./createShippingSlice";
import createCalculatorSlice, { CalculatorSlice } from "./createCalculatorSlice";
import createDokumentasiUISlice, { DokumentasiUISlice } from "./createDokumentasiUISlice";

export type StoreState = MainSlice &
  SessionSlice &
  OrderSlice &
  ShippingSlice &
  CalculatorSlice &
  DokumentasiUISlice;
export type CreateSlice<T = any> = (set: SetState<StoreState>, get: GetState<StoreState>) => T;

export const useStore = create<StoreState>(
  persist(
    (set, get) => ({
      ...createMainSlice(set, get),
      ...createSessionSlice(set, get),
      ...createOrderSlice(set, get),
      ...createShippingSlice(set, get),
      ...createCalculatorSlice(set, get),
      ...createDokumentasiUISlice(set, get),
    }),
    {
      name: "main-storage",
      whitelist: [
        "selectedCabang",
        "selectedAddress",
        "selectedOrder",
        "noTransaksi",
        "selectedJenisPakaian",
      ],
      version: 4,
    },
  ),
);

export default useStore;
