import React, { createElement, useEffect, useState } from "react";
import { Route, RouteProps, Redirect, useLocation, matchPath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import {
  setIdTransaksi,
  setInProtectedRoute,
  setNoTransaksiNoHp,
} from "../../redux/modules/Main/action";
import { persistor } from "../../redux";
import useStore from "../../stores/useStore";
import { initialState } from "@/stores/createMainSlice";
import Loading from "../../pages/Loading";
import useLinkInfo from "../../data/linkInfo";
import useCabang from "../../data/cabang";
import useToken from "../../helpers/useToken";
import { useSelectedCabang } from "@/data/selectedCabang";

const ProtectedRoute: React.FC<RouteProps> = ({
  children,
  component,
  render,
  path,

  ...rest
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = matchPath<any>(location.pathname, {
    path,
  })?.params;

  const { selectedCabang, selectCabang } = useStore();

  const { notransaksi } = params || {};
  const { data, loading: linkDataLoading, expired } = useLinkInfo(notransaksi);
  const { data: cabangList, loading: cabangLoading } = useCabang();

  const idTransaksi = useSelector((r: RootState) => r.main.idTransaksi);
  const token = useToken(notransaksi);

  const [loading, setLoading] = useState(true);

  const { data: dataSelectedCabang } = useSelectedCabang(data?.notransaksi);

  useEffect(() => {
    token && setLoading(false);
  }, [token]);

  useEffect(() => {
    dispatch(setIdTransaksi(notransaksi));
  }, [notransaksi]);

  useEffect(() => {
    (async () => {
      if (!linkDataLoading && data) {
        if (data.notransaksi_enksripsi && data.notransaksi) {
          if (idTransaksi !== data.notransaksi_enksripsi) {
            (useStore as any).persist.clearStorage();

            selectCabang(initialState.selectedCabang);

            await Promise.all([(useStore as any).persist.rehydrate(), persistor.purge()]);
          }
        }
      }
    })();
    dispatch(setNoTransaksiNoHp(data.notransaksi, data.no_hp, data.nama_customer));
  }, [data, idTransaksi, linkDataLoading]);

  useEffect(() => {
    if (dataSelectedCabang?.cabang && Array.isArray(cabangList)) {
      const cabang = cabangList.find((c) => c.nama_cabang === dataSelectedCabang?.cabang);

      if (cabang) {
        selectCabang(cabang);
      }
    }
  }, [dataSelectedCabang, cabangList]);

  useEffect(() => {
    dispatch(setInProtectedRoute(true));
    return () => {
      dispatch(setInProtectedRoute(false));
    };
  }, []);

  return (
    <Route path={path} {...rest}>
      {(props) =>
        loading
          ? createElement(Loading)
          : expired
          ? createElement(Redirect, { to: { pathname: "/A/" + notransaksi } })
          : props.match
          ? children
            ? children
            : component
            ? createElement<any>(component, props)
            : render
            ? render
            : null
          : null
      }
    </Route>
  );
};

export default ProtectedRoute;
