import React from "react";
import { Toaster } from "react-hot-toast";
import styles from "./Snackbar.module.scss";

const Snackbar: React.FC = () => (
  <Toaster
    position="bottom-center"
    toastOptions={{
      error: { className: styles.errorContainer, icon: null },
      blank: { className: styles.snackbar, icon: null },
    }}
  />
);

export default Snackbar;
