import React, { DetailedHTMLProps, HTMLAttributes } from "react";

type StandardProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type Props = StandardProps & { variant?: "primary" | "light" };

const Spinner: React.FC<Props> = ({ className, variant, ...rest }) => {
  className = Boolean(className) ? className : "";
  variant = Boolean(variant) ? variant : "primary";

  return <div {...rest} className={`spinner ${className} ${variant}`}></div>;
};

export default Spinner;
