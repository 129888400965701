import React, { useState } from "react";
import cn from "classnames";
import Tour, { ReactourStep } from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import useWindowSize from "../../helpers/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/modules";
import {
  setTourGuideShow,
  setCurrentTour,
  setCurrentStep,
} from "../../redux/modules/TourGuide/action";
import { isIOS } from "../../helpers/isIOS";
import useMedia from "use-media";
import styles from "./TourGuide.module.scss";
import "./TourGuide.scss";

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type HelperContentProps = { badge?: string; title?: string };

const TourGuidePemilihanJenisKain: React.FC = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();

  const currentStep = useSelector((r: RootState) => r.tour.currentStep);
  const currentTour = useSelector((r: RootState) => r.tour.currentTour);
  const isShow = useSelector((r: RootState) => r.tour.isShow);
  const onClose = () => {
    dispatch(setCurrentStep(0));
    dispatch(setTourGuideShow(false));
  };

  const [updateTrigger, triggerToUpdate] = useState("");
  const [maskSpace, setMaskSpace] = useState<number>();

  const onUnderstandBtnClick = () => {
    localStorage.setItem(`tour-${currentTour}-done`, "1");

    onClose();
  };

  const maxWidth = useMedia({ maxWidth: "430px" });

  const leftGap = width && width > 430 ? (width - 430) / 2 : maxWidth ? 0 : 0;

  const tour0: ReactourStep[] = [
    {
      selector: "none",
      content: ({ close }) => (
        <div className={styles.tour0}>
          <div className={styles.tour0title}>Panduan Belanja Knitto</div>
          <div>
            Selamat datang di Website Order kami. Silahkan ikuti panduan kami, Untuk mempermudah
            Anda dalam bertransaksi.
          </div>
          <button
            className={cn(styles.navButton, styles.tour0next)}
            onClick={() => {
              localStorage.setItem("tour-0-done", "1");
              dispatch(setCurrentTour(1));
            }}
          >
            Ikuti Panduan Belanja
          </button>
          <button
            className={styles.tour0skip}
            onClick={() => {
              const persitPromise = Array(5)
                .fill(0)
                .map((_, i) => localStorage.setItem(`tour-${i}-done`, "1"));

              Promise.all(persitPromise).finally(close);
            }}
          >
            Lewati
          </button>
        </div>
      ),
      action: () => {
        triggerToUpdate("0");
      },
    },
  ];

  const tour1: ReactourStep[] = [
    {
      selector: '[data-tour="helpButton"]',
      content: (
        <HelperContent badge="1 dari 2" title="Menu Bantuan">
          Klik pada icon tanda tanya untuk langsung menghubungi Customer Service Kami
        </HelperContent>
      ),
      action() {
        setMaskSpace(20);
        triggerToUpdate("1");
      },
      position: [leftGap, 82],
    },
    {
      selector: '[data-tour="pilihCabang"]',
      content: (
        <HelperContent badge="2 dari 2" title="Lokasi Cabang">
          Klik untuk memilih lokasi Cabang Knitto yang diinginkan
        </HelperContent>
      ),
      action() {
        setMaskSpace(6);
        triggerToUpdate("0");
      },
      position: [leftGap, 250],
    },
  ];

  const tour2: ReactourStep[] = [
    {
      selector: '[data-tour="infoIcon"]',
      content: (
        <HelperContent badge="1 dari 2" title="Informasi Harga">
          Klik pada icon informasi untuk mendapatkan list harga roll dan eceran
        </HelperContent>
      ),
      action() {
        setMaskSpace(16);
        triggerToUpdate("2");
      },
      position: "bottom",
      style: {
        transform: `translate(${leftGap}px, ${isIOS() ? "38.5vh" : "46.5vh"})`,
      },
    },
    {
      selector: '[data-tour="stdQty"]',
      content: (
        <HelperContent badge="2 dari 2" title="Gunakan Jumlah Standard">
          Checklist tombol ini jika ingin otomatis menambahkan jumlah aksesoris sesuai standard
        </HelperContent>
      ),
      action() {
        setMaskSpace(20);
        triggerToUpdate("0");
      },
      position: "top",
      style: {
        transform: `translate(${leftGap}px, ${
          height && height < 731 ? (isIOS() ? "43vh" : "50vh") : isIOS() ? "50vh" : "57.5vh"
        })`,
      },
    },
  ];

  const tour3: ReactourStep[] = [
    {
      selector: '[data-tour="addressMaps"]',
      content: (
        <HelperContent badge="1 dari 2" title="Cari Alamat melalui Maps">
          Klik tombol “Cari dengan Maps” apabila Anda ingin menentukan titik lokasi Anda pada maps
        </HelperContent>
      ),
      position: width && width > 430 ? [leftGap, 280] : "bottom",
      action() {
        triggerToUpdate("3");
      },
    },
    {
      selector: '[data-tour="mainAddress"]',
      content: (
        <HelperContent badge="2 dari 2" title="Simpan Alamat Utama">
          Klik pada tombol switch untuk menjadikan lokasi yang sedang Anda pilih menjadi alamat
          utama
        </HelperContent>
      ),
      position: width && width > 430 ? [leftGap, 350] : "top",
      action() {
        triggerToUpdate("0");
      },
    },
  ];

  const tour4: ReactourStep[] = [
    {
      selector: '[data-tour="opsiPengambilan"]',
      content: (
        <HelperContent badge="1 dari 1" title="Pilih Metode Pengiriman Pesanan">
          Klik pada salah satu pilihan di atas untuk mengganti cara pesanan Anda akan dikirimkan
        </HelperContent>
      ),
      action() {
        triggerToUpdate("4");
        setMaskSpace(5);
      },
      position: [leftGap, 157],
    },
  ];

  const tours = [tour0, tour1, tour2, tour3, tour4];

  return (
    <>
      <Tour
        update={updateTrigger + "-" + currentTour + "-" + currentStep}
        updateDelay={180}
        onAfterOpen={(target) => {
          disableBodyScroll(target);
          sessionStorage.setItem("wasOpenTourGuide", "1");
        }}
        startAt={currentStep}
        onBeforeClose={(target) => {
          enableBodyScroll(target);
        }}
        steps={tours[currentTour]}
        getCurrentStep={(currentStep) => dispatch(setCurrentStep(currentStep))}
        goToStep={(() => currentStep)()}
        isOpen={isShow}
        maskClassName={styles.helperMask}
        className={styles.helper}
        highlightedMaskClassName={styles.highlightMask}
        rounded={8}
        maskSpace={maskSpace}
        onRequestClose={onClose}
        showNavigationNumber={false}
        closeWithMask={false}
        disableInteraction
        disableDotsNavigation
        showNavigation={false}
        showNumber={false}
        showCloseButton={false}
        inViewThreshold={isIOS() ? undefined : 44}
        CustomHelper={({ content, current, totalSteps, gotoStep }) => {
          return (
            <div>
              <div className={currentTour === 0 ? styles.container : styles.containerLight}>
                {content}
                {currentTour > 0 && (
                  <div className={styles.buttons}>
                    <CloseButton onClick={onUnderstandBtnClick} />
                    {current > 0 && <PrevButton onClick={() => gotoStep(current - 1)} />}
                    {current >= totalSteps - 1 ? (
                      <UnderstandButton onClick={onUnderstandBtnClick} />
                    ) : (
                      <NextButton onClick={() => gotoStep(current + 1)} />
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        }}
      />
    </>
  );
};

export default TourGuidePemilihanJenisKain;

const PrevButton: React.FC<ButtonProps> = ({ className, ...props }) => (
  <button {...props} className={cn(styles.navButton, styles.prev, className)}>
    Kembali
  </button>
);

const NextButton: React.FC<ButtonProps> = ({ className, ...props }) => (
  <button {...props} className={cn(styles.navButton, className)}>
    Selanjutnya
  </button>
);

export const UnderstandButton: React.FC<ButtonProps> = ({ className, ...props }) => (
  <button {...props} className={cn(styles.navButton, className)}>
    Mengerti
  </button>
);

const CloseButton: React.FC<ButtonProps> = ({ className, ...props }) => (
  <button {...props} className={cn(styles.navButton, styles.close, className)}>
    Tutup
  </button>
);

export const HelperContent: React.FC<HelperContentProps> = ({ badge, title, children }) => (
  <div className={styles.content}>
    {badge && <div className={styles.badge}>{badge}</div>}
    {title && <div className={styles.title}>{title}</div>}
    {children && <div>{children}</div>}
  </div>
);
