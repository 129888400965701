import { CreateSlice } from "./useStore";
import { Order } from "../types";

export type OrderSlice = {
  selectedOrder: Order;
  selectOrder: (order: Order) => any;
  isCheckedOrder: boolean;
  setCheckedOrder: (isCheckedOrder: boolean) => any;
  orders: Order[];
  setOrder: (order: Order) => any;
  deleteOrder: (order: Order) => any;
  setRefId: (refId: string) => void;
};

export const initialState = {
  selectedOrder: {
    no_order: "",
    no_transaksiweb: "",
    tanggal_order: new Date(),
    cabang: "",
    ekspedisi: "",
    status_order: "",
    status_bayar: "",
    fhoto_resi: "",
    tagihan: 0,
    ongkir: 0,
    total_bayar: 0,
    jenis_pembayaran: "",
    pembayaran: 0,
    detail_data: [],
    detail_katalog: [],
    detail_sample: [],
    rincian_subtotal: [],
    refId: "",
  },
  orders: [],
  isCheckedOrder: false,
};

const createOrderSlice: CreateSlice<OrderSlice> = (set, get): OrderSlice => ({
  ...initialState,
  selectOrder: (selectedOrder) => set({ selectedOrder }),
  setCheckedOrder: (isCheckedOrder) => set({ isCheckedOrder }),
  setOrder: (orders) =>
    set((state) => ({
      orders: [...state.orders, orders],
    })),
  deleteOrder: (orders) =>
    set((state) => ({
      orders: state.orders.filter((order) => order.no_order !== orders.no_order),
    })),
  setRefId: (refid) =>
    set((state) => ({
      selectedOrder: { ...state.selectedOrder, refId: refid },
    })),
});

export default createOrderSlice;
