import { PropsWithChildren } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import useStore from "@/stores/useStore";

export type DokumentasiUIProviderProps = PropsWithChildren<{}>;

export default function DokumentasiUIProvider({ children }: DokumentasiUIProviderProps) {
  const toggleShowDokumentasi = useStore((state) => state.toggleShowDokumentasi);

  useHotkeys(
    "ctrl+F9",
    () => {
      toggleShowDokumentasi();
    },
    [toggleShowDokumentasi],
  );

  return <>{children}</>;
}
