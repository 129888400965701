import { RefObject } from "react";

export type RefsObject = RefObject<HTMLTableRowElement>[];

export interface KonfirmasiNoOrderItem {
  noOrder: string;
  statusOrder: string;
  idKonfirmasi: number;
  totalBayar: number;
  checked: boolean;
}
export interface KonfirmasiAtasNamaItem {
  nama: string;
}

export interface KonfirmasiRekening {
  rekening: string;
  kodeKasCabang: string;
  kodeKas: string;
  cabang: string;
}

export interface KonfirmasiItem {
  rekening: string;
  jenis: string;
  noOrder: string;
  tanggalTransfer: Date;
  atasNama: string;
  nominal: string;
  files: any;
  fileUpload: any;
}

export interface KonfirmasiRincianItem {
  noOrder: string;
  nominal: string;
  rekening: string;
  tanggalTransfer: Date;
  atasNama: string;
  nominalOrder: string;
  fhoto: string;
  folder: string;
}

export interface konfirmasiState {
  konfirmasinoorderitems: KonfirmasiNoOrderItem[];
  konfirmasiatasnamaitems: KonfirmasiAtasNamaItem[];
  konfirmasiItems: KonfirmasiItem[];
  konfirmasirekeningitems: KonfirmasiRekening[];
  KonfirmasiRincianItems: KonfirmasiRincianItem[];
}

export const SET_KONFIRMASINOORDER = "@konfirmasi/SET_KONFIRMASINOORDER";
export const SET_KONFIRMASIATASNAMA = "@konfirmasi/SET_KONFIRMASIATASNAMA";
export const SET_KONFIRMASIREKENING = "@konfirmasi/SET_KONFIRMASIREKENING";
export const SET_KONFIRMASI = "@konfirmasi/SET_KONFIRMASI";
export const SET_KONFIRMASIRINCIAN = "@konfirmasi/SET_KONFIRMASIRINCIAN";

interface setStatusOrderItems {
  type: typeof SET_KONFIRMASINOORDER;
  payload: { konfirmasinoorderitems: KonfirmasiNoOrderItem[] };
}
interface setKonfirmasiAtasNamaItems {
  type: typeof SET_KONFIRMASIATASNAMA;
  payload: { konfirmasiatasnamaitems: KonfirmasiAtasNamaItem[] };
}
interface setKonfirmasiRekeningItems {
  type: typeof SET_KONFIRMASIREKENING;
  payload: { konfirmasirekeningitems: KonfirmasiRekening[] };
}

interface setKonfirmasiItems {
  type: typeof SET_KONFIRMASI;
  payload: { konfirmasiItems: KonfirmasiItem[] };
}
interface setKonfirmasiRincianItems {
  type: typeof SET_KONFIRMASIRINCIAN;
  payload: { konfirmasirincianitems: KonfirmasiRincianItem[] };
}

export type konfirmasiActionTypes =
  | setStatusOrderItems
  | setKonfirmasiItems
  | setKonfirmasiRekeningItems
  | setKonfirmasiAtasNamaItems
  | setKonfirmasiRincianItems;
