import { HOST_ANALYTICS_SERVICE } from "@/strings";
import axios from "axios";

const useReqRefId = async () => {
  const requestRefID = await axios.get(`${HOST_ANALYTICS_SERVICE}/event-tracker/ref-id`);
  if (requestRefID.status === 201) {
    return requestRefID.data.result.refID;
  }
  return "";
};

export default useReqRefId;
