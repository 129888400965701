import { CreateSlice } from "./useStore";

export type SessionSlice = {
  token: string;
  idTransaksi?: string | null;
  noTransaksi: string;
  inProtectedRoute: boolean;
  setToken: (token: string) => any;
  setIdTransaksi: (idTransaksi: string | null) => any;
  setNoTransaksi: (noTransaksi: string) => any;
  setInProtectedRoute: (inProtectedRoute: boolean) => any;
};

export const initialState = {
  token: "",
  idTransaksi: undefined,
  noTransaksi: "",
  inProtectedRoute: false,
};

const createSessionSlice: CreateSlice<SessionSlice> = (set): SessionSlice => ({
  ...initialState,
  setToken: (token) => set({ token }),
  setIdTransaksi: (idTransaksi) => set({ idTransaksi }),
  setNoTransaksi: (noTransaksi) => set({ noTransaksi }),
  setInProtectedRoute: (inProtectedRoute) => set({ inProtectedRoute }),
});

export default createSessionSlice;
