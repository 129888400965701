import { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useSWR from "swr";
import axios from "axios";
import { HOST } from "../strings";

const URL = HOST + "/auth/ceklink/";

const getVisitorId = () => {
  const visitorId = localStorage.getItem("knittoVisitorId");
  if (!visitorId) {
    const newVisitorId = uuidv4();
    localStorage.setItem("knittoVisitorId", newVisitorId);
    return newVisitorId;
  }

  return visitorId;
};

const fetcher = async (_: string, notransaksi: any, no_order?: string, pilihanmenu?: string) => {
  if (!notransaksi) return;
  const fingerprint = getVisitorId();

  const response = await axios.post(
    URL,
    { notransaksi, no_order, pilihanmenu, fingerbrowser: fingerprint },
    {
      headers: { "Content-Type": "application/json" },
      timeout: 7000,
    },
  );

  return response.data;
};

type Data =
  | {
      id?: number;
      notransaksi?: string;
      token?: string;
      no_hp?: string;
      kategori?: string;
      tanggal?: Date;
      notransaksi_enksripsi?: string;
      cabang?: string;
      nama_customer?: string;
    }
  | { [key: string]: any };

type Options = {
  showLinkExpired?: boolean;
  pilihanmenu?: string;
};

const DEFAULT_OPTS: Options = { showLinkExpired: true };

export const useLink = (noTransaksi: any, options: Options = DEFAULT_OPTS) => {
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(String(prop)),
  });

  const noOrderQueryParam = params.no_order;

  const isKonfirmasiPembayaranPage = useMemo(() => {
    let url = location.pathname.split("/");
    return url[1] === "konfirmasi-pembayaran";
  }, []);

  const isBuktiPembayaranPage = useMemo(() => {
    let url = location.pathname.split("/");
    return url[1] === "bukti-pembayaran";
  }, []);

  const pilihanMenu = useMemo(() => {
    if (isKonfirmasiPembayaranPage || isBuktiPembayaranPage) {
      return "konfirmasi-pembayaran";
    } else {
      options.pilihanmenu;
    }
  }, [isKonfirmasiPembayaranPage, isBuktiPembayaranPage, options.pilihanmenu]);

  const isKonfirmasiPembayaranMenu = useMemo(() => {
    return pilihanMenu === "konfirmasi-pembayaran";
  }, [pilihanMenu]);

  const [payload, setPayload] = useState<Data>({});
  const { data, mutate, error } = useSWR(
    [URL, noTransaksi, noOrderQueryParam, pilihanMenu],
    fetcher,
  );
  const { showLinkExpired } = options;

  const isNoOrderSudahTerbayar = useMemo(() => {
    return data?.values?.message === "no transaksi Sudah Digunakan Konfirmasi-Pembayaran";
  }, [data?.values?.message]);

  const loading = !data && !error;
  const expired = data && data.status !== 200;

  useEffect(() => {
    if (expired && showLinkExpired) {
      if (isKonfirmasiPembayaranMenu && noOrderQueryParam && isNoOrderSudahTerbayar) {
        window.open("/expired/" + noTransaksi + "?no_order=" + noOrderQueryParam, "_self");
      } else {
        window.open("/A/" + noTransaksi, "_self");
      }
    }
  }, [expired]);

  useEffect(() => {
    let payload: Data =
      data && data.values
        ? data.values.token
          ? data.values
          : data.values.data
          ? data.values.data
          : {}
        : {};

    if (data?.values?.dataExpire?.nohp) {
      payload = { ...payload, no_hp: data.values.dataExpire.nohp };
    }

    if (data?.values?.dataExpire?.token) {
      payload = { ...payload, token: data.values.dataExpire.token };
    }

    setPayload(payload);
  }, [data]);

  return {
    expired,
    loading,
    error,
    data: payload,
    mutate,
  };
};

export default useLink;
