export const KNITTO_WHATSAPP_NUMBER = process.env.REACT_APP_KNITTO_WHATSAPP_NUMBER || "";

export const HOST = process.env.REACT_APP_BACKEND_HOST || "";
export const HOST_ANALYTICS_SERVICE = process.env.REACT_APP_ANALYTICS_HOST || "";

export const WEBSITE_KNITTO_URL = process.env.REACT_APP_WEBSITE_KNITTO_URL || "";
export const CHECK_STOCK_URL = process.env.REACT_APP_CHECK_STOCK_URL || "https://stock.knitto.org";

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

export const HOTJAR_ID = 2462059;
export const GTM_ID = process.env.REACT_APP_GTM_ID || "";

export const GA_ID = process.env.REACT_APP_GA_ID || "";
export const ANALYTICS_HOST2 = process.env.REACT_APP_ANALYTICS_HOST2;
export const FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL_ID;
export const IDTIKTOKTEMP = process.env.REACT_APP_TIKTOK_PIXEL_ID;
export const ANYTRACK_ID = process.env.REACT_APP_ANYTRACK_ID;
export const ANYTRACK_HOST = process.env.REACT_APP_ANYTRACK_HOST;
