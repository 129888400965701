import { DetailedHTMLProps, HTMLAttributes, forwardRef } from "react";
import styles from "./Layout.module.scss";
import { isIOS } from "../../helpers/isIOS";
import cx from "classnames";

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  iOS?: boolean;
  modal?: boolean;
  fixModalHeight?: boolean;
};

const Page = forwardRef<HTMLDivElement, Props>(
  ({ className, iOS, modal, fixModalHeight, ...rest }, ref) => {
    iOS = iOS !== undefined ? iOS : typeof window !== "undefined" ? isIOS() : false;
    const pageClassName = cx(className, {
      [styles.page]: true,
      [styles.pageIsIos]: iOS,
      [styles.pageNonIos]: !iOS,
      [styles.pageModal]: modal,
      [styles.fixModalHeight]: fixModalHeight,
    });
    return <div ref={ref} {...rest} className={pageClassName} />;
  },
);

export default Page;
