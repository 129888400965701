declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const PAGE_CART = "cart";
export const PAGE_CHECK_STOCK = "stock";
export const PAGE_ORDER = "order";
export const PAGE_MINTA_SAMPLE = "sample";
export const PAGE_CATALOG = "catalog";
export const PAGE_ADDRESS = "address";
export const PAGE_BOOKING = "booking";
export type PageType =
  | typeof PAGE_CART
  | typeof PAGE_CHECK_STOCK
  | typeof PAGE_ORDER
  | typeof PAGE_MINTA_SAMPLE
  | typeof PAGE_CATALOG
  | typeof PAGE_ADDRESS
  | typeof PAGE_BOOKING;

export type KainInfo = {
  [key: string]: string | number;
};

export type Cabang = {
  id: number;
  nama_cabang: string;
  kota: string;
  alias: string;
  alamat: string;
};

export type WarnaKain = {
  id: number;
  slug: string;
  Warna: string;
  hex: string;
  hex2: string;
  stokKain: string;
  stokAksesoris: "ADA" | "TIDAK ADA" | "-";
  noKatalog: number;
  is_new: boolean;
  image_url: string;
  created_at: Date;
  accessories?: StockAksesoris[];
  is_twocolor: boolean;
  tag: string;
  id_kain: number;

  harga?: {
    harga_rollan: number;
    harga_diatas: number;
    harga_dibawah: number;
  };
};

export type JenisKain = {
  id: number;
  "Jenis Kain": string;
  warnaKainLength?: number;
};

export type GroupKain = {
  id: number;
  "Group Kain": string;
  JenisKainOverview: string;
  JenisKain: JenisKain[];
};

export interface CatalogItem {
  slug: string;
  id: number;
  namaKatalog: string;
  harga: number;
  berat: number;
  imgUrl: string;
  stok: number;
}

export interface Address {
  id: number;
  receiverName: string;
  province: string;
  city: string;
  district: string;
  subDistrict: string;
  phone: string;
  rtRw: string;
  zip: string;
  address: string;
  status: string;
  label: string;
  notes?: string;
}

export interface Aksesoris {
  id: number | string;
  name: string;
  qtyStandard: number;
  qtyType: string;
  description: string;
  alias: string;
  body: string;
}

export type AksesorisQty = {
  qty: string;
  isStandardQty: boolean;
};

export type AksesorisQtyList = {
  [key: number]: AksesorisQty;
};

export interface QuantityType {
  slug?: string;
  id: string | number;
  quantity: string;
  quantityType: string;
  pricePerUnit: number | bigint;
  name: string;
  tipeKain: string;
}

export interface CartItem {
  slug?: string;
  id: number;
  jenisKain: string;
  warnaKain: string;
  body: QuantityType[];
  aksesoris: QuantityType[];
  totalHarga: number;
  hex?: string;
  hex2?: string;
  is_twocolor?: boolean;
  image_url?: string;
  tag?: string;
}

interface CartGeneric {
  id: number;
  name: string;
  quantity: string;
  quantityType: string;
  price: number | bigint;
  subTotalPrice: number;
  idDetailOrder: number;
}

export interface CartSample extends CartGeneric {
  slug?: string;
  idOrder: number;
  idKain: number;
  idWarna: number;
  jenisKain: string;
  warnaKain: string;
  hex?: string;
  hex2?: string;
  is_twocolor?: boolean;
  image_url?: string;
}

export interface CartKatalog extends CartGeneric {
  slug?: string;
  idCatalog: number;
  imageUrl?: string;
}

export interface BookingItem {
  id: number;
  name: string;
  jenisKain: string;
  warnaKain: string;
  qty: string;
  hex?: string;
  hex2?: string;
  is_twocolor?: boolean;
  image_url?: string;
}

export type StockKain = {
  cabang: string;
  jenis_kain: string;
  warna: string;
  jmlstok: number;
  stok: string;
  max_stok: number;
  stokaksesoris?: "ADA" | "TIDAK ADA";
};

export type StockAksesorisHarga = {
  no: number;
  operator: string;
  tanggal_efektif: Date;
  status: number;
  harga_selisih: number;
  jenis: string;
};

export type StockAksesoris = {
  bodynya: string;
  aksesorisnya: string;
  aksesoris: string;
  qty_standar: number;
  jenis_qty: string;
  catatan: string;
  stokaksesoris: number;
  harga?: {
    eceran?: StockAksesorisHarga;
    rollan?: StockAksesorisHarga;
  };
};

export type OutOfStockOrder = Partial<{
  id_order: number;
  nama_kain: string;
  jenis_Warna: string;
  jenis_qty: "KG" | "ROLL";
  qty: number;
  hex8: string;
  image_url: string;
  id_katalog: string;
  nama_katalog: string;
  stok: number;
}>;

export type Rekening = {
  no_rekening: string;
  bank: string;
  atas_nama: string;
  cabang: string;
};

export type Order = {
  no_order: string;
  no_transaksiweb: string;
  tanggal_order: Date;
  cabang: string;
  ekspedisi: string;
  status_order: string;
  status_bayar: string;
  fhoto_resi?: string;
  no_resi?: string;
  tagihan: number;
  ongkir: number;
  jenis_pembayaran?: string;
  pembayaran: number;
  total_bayar: number;
  detail_data: OrderItem[];
  detail_katalog: OrderCatalogItem[];
  detail_sample: OrderSampleItem[];
  rincian_subtotal: RincianSubTotal[];
  refId: string;
};

export type Orders = Order[];

export type RincianSubTotal = {
  total_pesanan: number;
  biaya_lainlain: number;
  diskon: number;
  asuransi: number;
  subtotal: number;
};
export type OrderItem = {
  id: number;
  no_order: string;
  id_kain: number;
  id_warna: number;
  jenis_kain: string;
  warna: string;
  jenis_quantity: string;
  quantity: number;
  harga: number;
  status: number;
  sub_total: number;
  nama_fhoto?: string;
  [key: string]: any;
};

export type OrderCatalogItem = {
  nama_katalog: string;
  harga: number;
  qty: number;
  sub_total: number;
  nama_fhoto?: string;
};

export type OrderSampleItem = {
  nama_kain: string;
  jenis_warna: string;
  qty: number;
  harga: number;
  sub_total: number;
  hex8?: string;
};

export type OrderTotal = {
  beratkgan: string;
  beratrollan: string;
  total: number;
};

export type RegionResults = {
  id: number;
  village: string;
  district: string;
  city: string;
  province: string;
  postal_code: number;
  fullAddress: string;
};

export const ALAMAT_PENGIRIMAN_DITULIS = "@ModeInputAlamatPengiriman/ditulis";
export const ALAMAT_PENGIRIMAN_MENGGUNAKAN_MAPS = "@ModeInputAlamatPengiriman/menggunakanMaps";

export type ModeInputAlamatPengiriman =
  | typeof ALAMAT_PENGIRIMAN_DITULIS
  | typeof ALAMAT_PENGIRIMAN_MENGGUNAKAN_MAPS;

export const OPSI_DIKIRIM = "dikirim";
export const OPSI_DIAMBIL = "diambil";

export type OpsiPengambilan = typeof OPSI_DIKIRIM | typeof OPSI_DIAMBIL;

export const OPSI_CLOTHES = "totalClothes";
export const OPSI_CLOTH = "totalCloth";

export type OpsiSwitcher = typeof OPSI_CLOTHES | typeof OPSI_CLOTH;

export type Clothes = {
  id: number;
  name: string;
  img_url: string;
  clothes_type_id: number;
};

export type ClothesType = {
  id: number;
  name: string;
  clothes: Clothes[];
};

export type FabricVariant = {
  id: number;
  name: string;
  oldName: string;
};

export type FabricColour = {
  id: number;
  name: string;
  color_hex: string;
  image_url: string;
};

type CalcResQty = {
  clothes: React.ReactText;
  body: React.ReactText;
  accessories: React.ReactText;
};
type CalcResPrice = {
  body: React.ReactText;
  accessories: React.ReactText;
  total: React.ReactText;
};
export type CalcResult = {
  fabric_name: string;
  qty: {
    size: {
      s?: CalcResQty;
      m?: CalcResQty;
      l?: CalcResQty;
      xl?: CalcResQty;
      [key: string]: CalcResQty | undefined;
    };
    total: {
      body: number;
      accessories: number;
    };
  };
  prices: {
    kg: CalcResPrice;
    roll: CalcResPrice;
    total: {
      body: React.ReactText;
      accessories: React.ReactText;
      grandTotal: React.ReactText;
    };
  };
};

export type Expedition = {
  nama: string;
  jenis_ekspedisi?: "STATION" | "" | null;
};
